<script lang="ts" setup>
import { computed, onUnmounted, ref } from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { REWARDS_MODAL } from '@/constants/modals';
import {
	CHANGE_REWARD,
	CLAIM,
	SELECT_REWARD,
	REDEEM_REWARD_WELCOME_BONUS,
} from '@/locales/constants';
import PartnersList from '@/views/surveys/components/user-dashboard/PartnersListBlured.vue';
import SelectedRewardDetails from '@/views/surveys/components/user-dashboard/SelectedReward.vue';
import {
	PrimeButton,
	PrimeText,
	PrimeSkeleton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
const { createNewModal } = useModalStorage();

const userStore = useUserStore();
const rewardsStore = useRewardsStore();
const router = useRouter();
const route = useRoute();
const modalStorage = useModalStorage();
const { isMobile } = useScreenSize();

const {
	isStoreLoaded,
	collectedCoins,
	userSelectedReward,
	minWelcomeBonusClaimBalance,
	isWelcomeBonusClaimed,
	isWelcomeBonusSelected,
} = storeToRefs(userStore);

const channelName = ref('');

const isRewardsPage = computed(() => route?.name === 'confirm-claim');

const isDetailsVisible = computed(() => {
	return (
		isWelcomeBonusSelected.value &&
		!isWelcomeBonusClaimed.value &&
		collectedCoins.value < minWelcomeBonusClaimBalance.value
	);
});

const isCoinsTargetReached = computed(() => {
	return userSelectedReward.value
		? collectedCoins.value >= userSelectedReward.value.coin_value &&
				!isRewardsPage.value
		: false;
});

const isClaimAvailable = computed(() => {
	if (!isWelcomeBonusClaimed.value) {
		return collectedCoins.value >= minWelcomeBonusClaimBalance.value;
	} else {
		return isCoinsTargetReached.value;
	}
});

const openRewardsModal = () => {
	createNewModal(REWARDS_MODAL, {
		position: 'bottom',
	});
};

const handleClaimCard = () => {
	if (isClaimAvailable.value) {
		rewardsStore.setClaimInitialized(true);
		router.push({
			name: 'confirm-claim',
		});
	}
};

onUnmounted(() => {
	if (window.Echo) {
		window.Echo.leave(channelName.value);
	}
});
</script>

<template>
	<div class="user-rewards">
		<PrimeSkeleton
			v-if="!isStoreLoaded"
			:height="isMobile ? '7.625rem' : '3.625rem'"
		/>
		<template v-else>
			<div class="user-rewards-row">
				<div
					class="user-rewards-details"
					:class="{ centered: !userSelectedReward }"
				>
					<SelectedRewardDetails
						v-if="userSelectedReward"
						:selected-reward="userSelectedReward"
						:collected-coins="collectedCoins"
					/>
					<PartnersList v-else />
				</div>

				<div class="user-rewards-actions">
					<PrimeButton
						v-if="userSelectedReward"
						:label="$t(CHANGE_REWARD)"
						type="outline"
						rounded="rounded"
						variant="grey"
						transform="uppercase"
						@click="openRewardsModal"
					/>
					<PrimeButton
						v-else
						:label="$t(SELECT_REWARD)"
						type="outline"
						rounded="rounded"
						variant="grey"
						transform="uppercase"
						class="select"
						@click="openRewardsModal"
					/>
					<PrimeButton
						v-if="isCoinsTargetReached"
						:label="$t(CLAIM)"
						type="gradient"
						rounded="rounded"
						transform="uppercase"
						:disabled="!isClaimAvailable"
						@click="handleClaimCard"
					/>
				</div>
			</div>

			<PrimeText
				v-if="isDetailsVisible"
				tag="div"
				class="user-rewards-bonus"
				:size="isMobile ? 'xs' : 'sm'"
				v-html="
					`${$t(REDEEM_REWARD_WELCOME_BONUS, {
						amount: minWelcomeBonusClaimBalance,
					})}`
				"
			/>
		</template>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins';

.user-rewards {
	padding: 0.75rem;
	background-color: var(--white);
	border-radius: 0.75rem;
	max-width: 100%;
	box-shadow: var(--box-shadow);

	@include breakpoint(tablet) {
		padding: 0;
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
	}
}

.user-rewards-row {
	display: flex;
	flex-direction: column;
}

.user-rewards-details {
	width: 100%;
}

.user-rewards-actions {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	width: 100%;
	margin-top: 0.875rem;

	.p-btn {
		flex: 1;
		height: 2.25rem;
	}

	@include breakpoint(tablet) {
		flex-direction: column;
		margin-top: 1.5rem;

		.p-btn.select {
			width: 15.75rem;
		}
	}
}

.user-rewards-bonus {
	margin: 0.875rem auto 0;
	text-align: center;
	font-size: 0.75rem;
	max-width: 19.875rem;

	.bold {
		display: inline-block;
		vertical-align: middle;
		font-weight: 700;
		font-size: 0.75rem;
	}

	@include breakpoint(tablet) {
		margin-top: 1.5rem;
		font-size: 0.875rem;
	}
}
</style>
